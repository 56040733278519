import Rest from '@/services/Rest';

/**
 * @typedef {AccessBrokerService}
 */
export default class AccessBrokerService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/access-broker'
}
