<template>
    <div class="container-fluid px-0 py-2">
        <div class="page-breadcrumb">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 align-self-center pl-4">
                        <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">relatórios - logs do sistema</h3>
                        <div class="d-flex align-items-center">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb m-0 p-0">
                                    <li class="breadcrumb-item"><a href="?app=dashboard.php">dashboard / relatórios / logs do sistema / portal do cliente</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="col-5 align-self-center text-right d-none">
                                        </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-11 px-lg-0">
                        <div class="row">
                        
                            <!-- <div class="col-6 col-md-6 col-lg-3 pr-lg-0 pt-3">
                                <div class="card h-100 border-right card-bg-dash">
                                    <div class="card-body">
                                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                                            <div>
                                                <div class="d-inline-flex align-items-center">
                                                    <h2 class="text-dark mb-1 font-weight-medium">938</h2>
                                                </div>
                                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">cliques</h6>
                                            </div>
                                            <div class="ml-auto mt-md-3 mt-lg-0">
                                                <span class="opacity-7 text-muted"><i class="far fa-hourglass font-25"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-6 col-md-6 col-lg-3 pr-0 pt-3">
                                <div class="card h-100" style="background-color: #212121 !important;">
                                    <div class="card-body">
                                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                                            <div class="">
                                                <h2 class="text-white mb-1 font-weight-medium">{{total_corretores_access}}</h2>
                                                <h6 class="font-weight-normal mb-0 w-100 text-white" style="color: #ffffff;">n˚ de corretores</h6>
                                            </div>
                                            <div class="ml-auto mt-md-3 mt-lg-0">
                                                <span class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe" style="color: #02b475;"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-6 col-md-6 col-lg-3 pt-3">
                                <div class="card h-100 border-right card-bg-dash">
                                    <div class="card-body">
                                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                                            <div>
                                                <div class="d-inline-flex align-items-center">
                                                    <h2 class="text-dark mb-1 font-weight-medium font-20">Exportar</h2>
                                                </div>
                                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate"><a href="">PDF</a> | <a href="">EXCEL</a></h6>
                                            </div>
                                            <div class="ml-auto mt-md-3 mt-lg-0 d-none d-lg-block">
                                                <span class="opacity-7 text-muted"><i class="far fa-list-alt font-25"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid qsuite-c-fluid-0 bg-white mt-4 pt-4">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-11 pt-1 pb-3">
                        <div class="row">
                            <div class="col-10 col-md-5 col-lg-5 pr-md-0 pr-0">
                                <div class="form-group d-flex">
                                    <input type="search" v-model="name" class="form-control campo-procurar" placeholder="Buscar por nome">
                                </div>
                            </div>
                            <div class="col-1 col-md-auto text-center align-self-center pl-md-2 pl-0 pr-0">
                                <p class="font-11 color-1">ou</p>
                            </div>
                            <div class="col-12 col-md-4 col-lg-5 pr-md-0">
                                <div class="form-group d-flex">
                                    <input type="search" v-model="document" class="form-control campo-procurar" placeholder="CPF">
                                </div>
                            </div>
                            <div class="col-12 col-md-2 col-lg-auto text-right">
                                <div class="form-group d-flex">
                                    <button type="submit" class="btn btn-info" @click="getLogsSystem">Buscar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 pt-4">
                        <div class="table-responsive">
                            <table id="zero_config" class="table table-striped table-bordered no-wrap">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Data/Horário acesso</th>
                                        <th>Data cadastro</th>
                                        <th>Imobiliária</th>
                                        <th>Código do corretor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="access_broker in access_broker" :key="access_broker.id">
                                        <td>{{access_broker.user.first_name + ' ' + access_broker.user.last_name}}</td>
                                        <td>{{access_broker.created_at}}</td>
                                        <td>{{access_broker.user_created_at}}</td>
                                        <td>{{access_broker.real_state["fantasy_name"]}}</td>
                                        <td>{{access_broker.user_id}}</td>
                                    </tr>
                                    
                                </tbody>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Data/Horário acesso</th>
                                        <th>Data cadastro</th>
                                        <th>Imobiliária</th>
                                        <th>Código do corretor</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    <div class="col-12">
                    <BasePagination
                        v-if="access_broker.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
                </div>
             
            </div>
        </div>
        
        <footer class="footer text-center text-muted">© 2021 | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a href="" target="_blank">Termos de uso</a> | <a href="" target="_blank">Política de privacidade.</a></footer></div>
    
</template>
<script>
 import AccessBroker from "@/services/resources/AccessBrokerService";
var service = AccessBroker.build();

export default {
    data(){
        return{
            access_broker: [],
            total_corretores_access: "",
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            total_clients: 0,
            name: '',
            document: ''
        }
    },
    methods:{

        getLogsSystem() {
            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            this.name.length ? data['name'] = this.name : null;
            this.document.length ? data['document'] = this.document : null;

            service.search(data).then((re) => {
  
                this.access_broker= re[0].data;
                this.total_corretores_access= re[1]
                this.pagination.totalPages = re.last_page;
                this.pagination.itens = re.total;
       
            });
        },
        toPage(page) {
            this.pagination.currentPage = page;
            this.getLogsSystem();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.getLogsSystem();
        },
        
    },
    mounted(){
        this.getLogsSystem();
    }
}
</script>